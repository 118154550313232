.chart-container {
  .js-plotly-plot {
    .plotly {
      .modebar {
        bottom: -25px;
        right: 5px;

        .modebar-group:last-child {
          .modebar-btn:last-child:hover {
            &::after {
              left: -15%;
            }
          }
        }
      }
    }
  }
}
