.react-sigma-controls {
  display: flex;
  box-sizing: initial;
  border: none;
  background: none;
}

.react-sigma-control {
  position: relative;
  display: inline-block;
  border: none;
  margin-right: 4px;
  background-color: #e2e4e7;
  border-radius: 4px;

  :not(:last-of-type) {
    margin-right: 0;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e4e7;
    border-radius: 4px;

    &.lasso-selection {
      &.active {
        background-color: #bddde8;
      }
    }
  }

  button[data-tooltip]:before {
    position: absolute;
    display: none;
    pointer-events: none;
    transition: opacity 0.3s;
    content: attr(data-tooltip);
    background-color: #33455f;
    color: #fff;
    font-size: 1rem;
    font-family: var(--font-family);
    line-height: 1.4;
    border-radius: 4px;
    padding: 4px 8px;
    max-width: 300px;
    margin-bottom: 14px;
    white-space: nowrap;
    font-weight: 500;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  button[data-tooltip]:hover:before {
    display: block;
  }

  &:last-of-type {
    button[data-tooltip] {
      &:before {
        left: 90%;
        transform: translateX(-90%);
      }
    }
  }
}
