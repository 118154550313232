.statistics-table {
  --rdg-background-color: none;
  overflow-x: hidden;

  &.side-panel {
    height: 200px;
  }

  &.modal {
    height: 280px
  }
}
